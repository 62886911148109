// src/components/KYC.js
import React, { useState } from 'react';
import { Box, Typography, TextField, Button, IconButton } from '@mui/material';
import { Upload } from '@mui/icons-material';
import '../styles/Kyc.css';

const KYC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [aadharNumber, setAadharNumber] = useState('');
  const [frontPhoto, setFrontPhoto] = useState(null);
  const [backPhoto, setBackPhoto] = useState(null);
  const [frontPhotoPreview, setFrontPhotoPreview] = useState(null);
  const [backPhotoPreview, setBackPhotoPreview] = useState(null);

  const handleFileChange = (event, setPhoto, setPhotoPreview) => {
    const file = event.target.files[0];
    setPhoto(file);
    setPhotoPreview(URL.createObjectURL(file));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic
  };

  return (
    <Box className="kyc-container">
      <Typography variant="h5" className="kyc-heading">Enter Details For KYC</Typography>
      <Typography variant="body2" className="kyc-subheading">
        You Need To Submit A Document That Shows That You Are Above 18 Years Of Age And Not A Resident Of Assam, Odisha, Sikkim, 
        Nagaland, Telangana, Andhra Pradesh, Tamil Nadu And Karnataka.
      </Typography>
      <form onSubmit={handleSubmit} className="kyc-form">
        <Typography variant="subtitle1">Enter details of Aadhar Card:</Typography>
        <TextField 
          label="Enter name"
          className="kyc-inputbox"
          variant="outlined" 
          fullWidth 
          margin="normal" 
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField 
          label="Email Id" 
          className="kyc-inputbox"
          variant="outlined" 
          fullWidth 
          margin="normal" 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField 
          label="dd-mm-yyyy" 
          className="kyc-inputbox"
          variant="outlined" 
          fullWidth 
          margin="normal" 
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={dob}
          onChange={(e) => setDob(e.target.value)}
        />
        <TextField 
          label="Aadhar Number" 
          className="kyc-inputbox"
          variant="outlined" 
          fullWidth 
          margin="normal" 
          value={aadharNumber}
          onChange={(e) => setAadharNumber(e.target.value)}
        />
        {frontPhotoPreview ? (
          <Box className="image-preview">
            <img src={frontPhotoPreview} alt="Front Aadhar Card" className="image-preview-img" />
          </Box>
        ) : (
          <Button
            variant="contained"
            component="label"
            fullWidth
            className="upload-button"
          >
            <Upload className="upload-icon" /> Upload front Photo of your Aadhar Card.
            <input
              type="file"
              hidden
              onChange={(event) => handleFileChange(event, setFrontPhoto, setFrontPhotoPreview)}
            />
          </Button>
        )}
        {backPhotoPreview ? (
          <Box className="image-preview">
            <img src={backPhotoPreview} alt="Back Aadhar Card" className="image-preview-img" />
          </Box>
        ) : (
          <Button
            variant="contained"
            component="label"
            fullWidth
            className="upload-button"
          >
            <Upload className="upload-icon" /> Upload back Photo of your Aadhar Card.
            <input
              type="file"
              hidden
              onChange={(event) => handleFileChange(event, setBackPhoto, setBackPhotoPreview)}
            />
          </Button>
        )}
        <Button type="submit" variant="contained" color="secondary" fullWidth className="submit-button">
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default KYC;
