import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import BottomMenuBar from '../components/BottomMenuBar';
import Home from '../pages/Home';
import Wallet from '../pages/Wallet';
import Profile from '../pages/Profile';
import Refer from '../pages/Refer';
import LudoClassicManual from '../pages/LudoClassicManual';
import AddCash from '../pages/AddCash';
import '../styles/Main.css';
import KYC from '../pages/Kyc';
import Support from '../pages/Support';
import Withdrawal from './Withdrawal';
import LudoPlaying from './LudoPlaying';
import BankDetails from './BankDetails';
import TransactionHistory from './TransactionHistory';
// import Aviator from './Aviator';
import Aviator2 from './Aviator2';
import ColourGame from './ColourGame';

const MainContent = ({setShowBottomMenuBar }) => {
  return (
    <div>
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="wallet" element={<Wallet />} />
        <Route path="profile" element={<Profile />} />
        <Route path="ludoclassicmanual" element={<LudoClassicManual />} />
        <Route path="refer" element={<Refer />} />
        <Route path="addcash" element={<AddCash />} />
        <Route path="withdrawal" element={<Withdrawal />} />
        <Route path="kyc" element={<KYC />} />
        <Route path="support" element={<Support />} />
        <Route path="bankdetails" element={<BankDetails />} />
        <Route path="ludoplaying" element={<LudoPlaying />} />
        <Route path="transactionhistory" element={<TransactionHistory />} />
        {/* <Route path="aviator" element={<Aviator />} /> */}
        <Route path="aviator2" element={<Aviator2 />} />
        <Route path="colourgame" element={<ColourGame setShowBottomMenuBar={setShowBottomMenuBar} />} />
      </Routes>
    </div>
  );
};

const Main = () => {
  const [showBottomMenuBar, setShowBottomMenuBar] = useState(true);

  return (
    <>
      <Header />
      <Sidebar />
      <div className="container">
        <MainContent setShowBottomMenuBar={setShowBottomMenuBar} />
      </div>
      {showBottomMenuBar && <BottomMenuBar />}
    </>
  );
};

export default Main;
