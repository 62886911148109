// src/components/Banner.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Banner.css';
import { Box, Typography } from '@mui/material';
import { GiSpeaker } from "react-icons/gi";

const Banner = () => {
  const [content, setContent] = useState('');

  // Function to fetch content from the API
  const fetchContent = async (pageName) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api2/pagecontent', {
        pageName  
      });

      if (response.data && response.data.Content) {
        const fetchedContent = response.data.Content;
        setContent(fetchedContent);
      } else {
        console.log('No content found in the response');
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log('Content not found');
      } else {
        console.log('Failed to call API:', error.message);
      }
    }
  };

  // useEffect to call fetchContent once the component is mounted
  useEffect(() => {
    fetchContent('GamePage');
  }, []);

  return (
    <div>
      <Box
        p={2}
        textAlign="center"
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          backgroundColor: 'transparent',
          color: '#FFF',
          position: 'relative',  // Ensure the parent container is positioned correctly for absolute positioning
          height: '50px', // Set a fixed height to the container
          display: 'flex', // Use flexbox to align items
          alignItems: 'center', // Center content vertically
        }}
      >
        {/* Display the speaker icon */}
       

        {/* Span element for animated content */}
        <Typography
        className='hearderhome'
          variant="h6"
          sx={{
            display: 'inline-block',
            position: 'relative',
            overflow: 'hidden', // Hide overflow
            whiteSpace: 'nowrap', // Prevent wrapping
            width: '100%', // Full width to ensure animation space
          }}
        >
          <div className='speaker'>
           <GiSpeaker style={{ fontSize: '24px', marginRight: '10px',float: 'left' }} />
           </div>
          <span className='movingclass'
          >
            {content || 'Loading...'} {/* Display content or 'Loading...' if content is empty */}
          </span>
        </Typography>
      </Box>
    </div>
  );
};

export default Banner;
