import React, { useRef, useState } from "react";
import "../styles/Popup.css";
import Upload from "../styles/Upload";
import Loader from "./loader/Loader";

const Popup = ({
  onClose,
  onSubmit,
  selectedFile,
  handleFileChange,
  isLoader = false,
  loader = false,
}) => {
  const imageRef = useRef();

  const handleSubmit = () => {
    if (selectedFile) {
      onSubmit(selectedFile);
    } else {
      alert("Please select a file.");
    }
  };

  const onHandleUploadButton = () => {
    imageRef.current.click();
  };

  console.log("selectedFile", selectedFile?.name);
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Upload Your Image</h2>
        <input
          className="popup--imageRef"
          type="file"
          ref={imageRef}
          onChange={handleFileChange}
        />
        <div className="popup__uploadAction">
          <button className="popup--button3" onClick={onHandleUploadButton}>
            <div className="popup__imageUpload">
              <Upload />
              <span>Select Image</span>
            </div>
          </button>
          <span className="popup__uploadText">
            {selectedFile ? selectedFile?.name : "No file uploaded"}
          </span>
        </div>
        <div className="popup__action">
          <button className="popup--button1" onClick={handleSubmit}>
            {isLoader && loader ? <Loader loader={loader} /> : "Submit"}
          </button>
          <button className="popup--button2" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
