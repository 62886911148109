import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "../styles/LudoPlaying.css";
import Popup from "../components/Popup";

const LudoPlaying = () => {
  const location = useLocation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [status, setStatus] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const game_id = location.state?.game_id;
  const [roomcode, setRoomCode] = useState("");
  const [mobile, setMobile] = useState("");

  const [gameid, setGameId] = useState(game_id);
  const [game, setGame] = useState(null);
  const [error, setError] = useState("");
  const [remainingTime, setRemainingTime] = useState(180); // 3 minutes in seconds
  const [timerEnded, setTimerEnded] = useState(false);
  const [loader, setLoader] = useState(false);
  const hasRefundBeenCalled = useRef(false);
  const [isEditingRoomCode, setIsEditingRoomCode] = useState(false); // New state for editing room code
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // State for disabling the button

  const pollingInterval = useRef(null); // Define pollingInterval here

  const copyCode = (roomCode) => {
    navigator.clipboard
      .writeText(roomCode)
      .then(() => {
        toast.success("Room Code Copied Successfully");
      })
      .catch((err) => {
        toast.error("Failed to copy");
        console.error("Failed to copy: ", err);
      });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleWinningResultRequest = async () => {
    setIsButtonDisabled(true);
    setStatus('1');
    if (!selectedFile) {
      alert("Please select a file first!");
      return;
    }
    try {
      setLoader(true);
      setLoading(true);
      let formdata = new FormData();
      formdata.append("game_id", gameid);
      formdata.append("resultid", mobile);
      formdata.append("playerimg", selectedFile);
      const { status, data } = await axios.post(
        "https://api.successfamily.live/api2/winningresultrequest",
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const { message } = data;
      if (status === 201) {
        toast.success(message, { position: "top-center" });
      } else {
        toast.warning(message, { position: "top-center" });
      }
    } catch (e) {
      toast.error(
        "Request failed: " + (e.response?.data?.message || e.message),
        { position: "top-center" }
      );
    } finally {
      setShowPopup(false);
      setLoader(false);
      setLoading(false);
      setSelectedFile(null);
    }
  };

  const handleLosingResultRequest = async () => {
    setIsButtonDisabled(true);
    setStatus('1');
    try {
      const response = await axios.post(
        "https://api.successfamily.live/api2/losingresultrequest",
        {
          game_id: gameid,
          resultid: mobile,
        }
      );

      const { message, status } = response.data;

      if (status === 1) {
        toast.success(message, { position: "top-center" });
      } else {
        toast.warning(message, { position: "top-center" });
      }
    } catch (error) {
      toast.error(
        "Request failed: " + (error.response?.data?.message || error.message),
        { position: "top-center" }
      );
    }finally {
      // Re-enable the button after 5 seconds
      setTimeout(() => {
        setLoading(false);
        setIsButtonDisabled(false);
      }, 5000);
    }
  };

  const cancelApi = async () => {
    setIsButtonDisabled(true);
    setStatus('1');
    if (hasRefundBeenCalled.current) return;
    setLoading(true);
    try {
      const response = await axios.post(  
        "https://api.successfamily.live/api2/cancelgame",
        { game_id: gameid }
      );
      hasRefundBeenCalled.current = true; // Set ref to true after successful call
      const { message, status } = response.data;
      toast.success(message, { position: "top-center" });
    } catch (error) {
      toast.error("Failed to process refund");
    }finally {
      // Re-enable the button after 5 seconds
      setTimeout(() => {
        setLoading(false);
        setIsButtonDisabled(false);
      }, 5000);
    }
  };

  const cancelApi2 = async () => {
    setIsButtonDisabled(true);
    setStatus('1');
    if (hasRefundBeenCalled.current) return;
    setLoading(true);
    try {
      const response = await axios.post(  
        "https://api.successfamily.live/api2/gamecancel",
        { game_id: gameid,
          PlayerCancel : mobile,
         }
      );
      hasRefundBeenCalled.current = true; // Set ref to true after successful call
      const { message, status } = response.data;
      toast.success(message, { position: "top-center" });
    } catch (error) {
      toast.error("Failed to process refund");
    }
    finally {
      // Re-enable the button after 5 seconds
      setTimeout(() => {
        setLoading(false);
        setIsButtonDisabled(false);
      }, 5000);
    }
  };

  const callRefundApi = async () => {
    setIsButtonDisabled(true);
    setStatus('1');
    if (hasRefundBeenCalled.current) return;
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.successfamily.live/api2/refundrequest",
        { game_id: gameid }
      );
      hasRefundBeenCalled.current = true; // Set ref to true after successful call
      toast.success("Refund processed successfully"); // Ensure the refund API is only called once
    } catch (error) {
      toast.error("Failed to process refund");
    }finally {
      // Re-enable the button after 5 seconds
      setTimeout(() => {
        setLoading(false);
        setIsButtonDisabled(false);
      }, 5000);
    }
  };
  const fetchStatus = async (mobileNumber, gameId) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api2/playerstatus', {
        mobileNumber,
        gameId
      });
      const { status } = response.data;
      setStatus(status);
      // if (status === '1') {
      //   clearInterval(pollingInterval.current); // Clear the interval if status is 1
      // }
    } catch (error) {
      console.error('Failed to fetch status:', error.message);
      setStatus('');
    }
  };
  useEffect(() => {
    if (!mobile || !gameid) return;

    // Function to start polling
    const startPolling = () => {
      pollingInterval.current = setInterval(() => {
        fetchStatus(mobile, gameid);
      }, 1000); // Poll every 5 seconds
    };

    startPolling();

    return () => {
      clearInterval(pollingInterval.current);
    };
  }, [mobile, gameid]);
  useEffect(() => {
    const userMobileNumber = getCookie("user");
    if (userMobileNumber) {
      setMobile(userMobileNumber);
    }
    const fetchRunningGames = async () => {
      try {
        const response = await axios.post(
          "https://api.successfamily.live/api2/runninggamesid",
          { game_id: gameid }
        );
        const data = response.data.recordset;

        if (data.length > 0) {
          setGame(data[0]);
        } else {
          setError("No running games found");
        }
      } catch (error) {
        console.error("Error fetching running games:", error);
        setError("Error fetching running games");
      }
    };

    if (gameid) {
      const interval = setInterval(fetchRunningGames, 1000);
      return () => clearInterval(interval);
    }
  }, [gameid]);

  useEffect(() => {
    if (!game || !game.battle_accept_time) return;

    const battleAcceptTime = new Date(game.battle_accept_time);
    const battleAcceptTimeIST = new Date(
      battleAcceptTime.getTime() - 5.5 * 60 * 60 * 1000
    ); // Convert to IST
    const endTime = new Date(battleAcceptTimeIST.getTime() + 3 * 60 * 1000); // battle_accept_time + 3 minutes

    const updateRemainingTime = () => {
      const now = new Date();
      const remaining = Math.max(0, Math.floor((endTime - now) / 1000));
      if (remaining === 0) {
        setTimerEnded(true);
        if (!hasRefundBeenCalled.current && (!game || !game.RoomCode)) {
          callRefundApi();
        }
      }
      setRemainingTime(remaining);
    };

    const interval = setInterval(updateRemainingTime, 1000);

    // Update remaining time immediately on mount
    updateRemainingTime();

    return () => clearInterval(interval);
  }, [game?.battle_accept_time, game?.RoomCode]);

  const handleRoomCode = (e) => {
    setRoomCode(e.target.value);
  };
  
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleUpdateRoom = async () => {
    if (!roomcode || roomcode.trim() === "") {
      toast.warning("Room Code cannot be blank", { position: "top-center" });
      return; // Exit function if roomcode is blank
    }
    setIsButtonDisabled(true);
    setLoading(true);
    try {
        const response = await axios.post(
            "https://api.successfamily.live/api2/updateroom",
            {
                roomcode: String(roomcode),  // Ensure roomcode is treated as a string
                game_id: parseInt(game_id, 10)
            }
        );
        const { message, status } = response.data;
        if (status === 1) {
            toast.success("Room Code Set Successfully", { position: "top-center" });
            setIsEditingRoomCode(false); // Exit edit mode after successful update
        } else {
            toast.warning(message, { position: "top-center" });
        }
    } catch (error) {
        toast.warning(
            "Failed Room Update: " +
            (error.response?.data?.message || error.message),
            { position: "top-center" }
        );
    }
    finally {
      setLoading(false);
    }
  };

  const handleEditRoomCode = () => {
    setIsEditingRoomCode(true); // Enable edit mode
  };

  const handlePopupClose = () => {
    setSelectedFile(null);
    setShowPopup(false);
  };
  const getCookie = (name) => {
    const nameEQ = name + '=';
    const cookiesArray = document.cookie.split(';');
    for (let i = 0; i < cookiesArray.length; i++) {
      let c = cookiesArray[i].trim();
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  return (
    <div className="ludo">
      <div className="game-room">
        {game ? (
          <>
            <div className="players-info">
              <div className="player-info">
                <div>
                  <h3>{game.player1_name}</h3>
                  <img
                    className="userimage"
                    src="../assets/images/Avatar2.png"
                    alt="User Image"
                  />
                  <p>Entry Fee: {game.amount}</p>
                </div>
                <div style={{ margin: "auto" }}>
                  <img
                    src="../assets/images/versus.png"
                    alt="vsimage"
                    style={{ width: "42px", height: "42px" }}
                  />
                </div>
                <div>
                  <h3>{game.player2_name}</h3>
                  <img
                    className="userimage"
                    src="../assets/images/Avatar2.png"
                    alt="User Image"
                  />
                  <p>Winning Prize: {game.winningamount}</p>
                </div>
              </div>
            </div>
            <div className="roomcode">
  {!isEditingRoomCode ? (
    // Show room code and "Edit Room" button when not in edit mode
    <>
      <h4>Room Code: {game.RoomCode}</h4>
      {game.player1_id === mobile && !timerEnded && !game.RoomCode && (
      <>
      <button className="EditRoom" onClick={handleEditRoomCode}>
      
      {loading ? 'Processing...' : ' Update Room'} <FontAwesomeIcon icon={faPencilAlt} />
      </button>
      </>
      )}
      {game.RoomCode && (
                <button onClick={() => copyCode(game.RoomCode)} disabled={loading}>
                  COPY CODE
                </button>
              )}
    </>
  ) : (
    // Show input field and "Update Room" button when in edit mode
    <>
     <div className="room-code">
     {game.player1_id === mobile && !timerEnded && !game.RoomCode && (
      <>
      <div className="edit-name">
      <div className="input-container">
        <input
          type="text"
          value={roomcode}
          onChange={handleRoomCode}
          placeholder="Enter Room Code"
        />
        </div>
        </div>
        <>
        {status === '0' && (
          <button className="setRoomBtn" onClick={handleUpdateRoom} disabled={loading}>
            {loading ? 'Processing...' : 'Update Room'}
          </button>
        )}
        </>
      </>
        )}
         </div>
    </>
  )}
</div>

<strong>
                  {game.RoomCode
                    ? ``
                    : !timerEnded &&
                      `Remaining Time For Room Code: ${formatTime(
                        remainingTime
                      )}`}
                </strong>
          </>
        ) : (
          <p>{error}</p>
        )}
      </div>
      <div className="app-links">
          <p>Play ludo game in Ludo King App</p>
          <img
            src="../assets/images/app-store-logo-android-4.png"
            alt="App Store"
          />
        </div>
        <div className="game-rules">
          <h4>Game Rules</h4>
          <ul>
            <li>
            For canceling Game VIDEO PROOF is necessary otherwise, the game will not be canceled. Click Here to download Video Recorder
Warning
अगर आप CHEATING करते हैं आपकी राशि जब्त कर ली जाएगी |
रिजल्ट अपडेट नहीं करने के लिए 50 Chips तक के चार्ज लिए जाएंगे 
            </li>
          </ul>
        </div>
        <div className="gamestatus">
          <h4>Update Game Status</h4>
          <p>
            After completion of your game, select the status of the game and
            post your screenshot below.
          </p>
          </div>
      {showPopup && (
        <Popup
        onClose={handlePopupClose}
        onSubmit={handleWinningResultRequest}
        selectedFile={selectedFile}
        handleFileChange={handleFileChange}
        isLoader={true}
        loader={loader}
      />
      )}
      <div className="buttons-container">
      {game?.RoomCode && (
        <>
         {status === '0' && (
          <>
          <button className="winning" onClick={() => setShowPopup(true)} disabled={loading || isButtonDisabled}>
            I Won
          </button>
          <button className="winning" onClick={handleLosingResultRequest} disabled={loading || isButtonDisabled}>
            {loading ? 'Processing...' : 'I Lost'}
          </button>
          </>
         )}
    </>
   )}

  {/* Render "Cancel Battle" button only if room code is not set */}
  {status === '0' && (
          <>
  <button className="winning" onClick={game && game.RoomCode ? cancelApi2 : cancelApi} disabled={loading || isButtonDisabled}>
  {loading ? 'Processing...' : ' Cancel Battle'}
</button>
</>
  )}


    
</div>

      <ToastContainer />
    </div>
  );
};

export default LudoPlaying;
