import React, { useEffect, useRef } from 'react';
import Banner from '../components/Banner';
import Games from '../components/Games';
import Footer from '../components/Footer';

function Home() {
  const audioRef = useRef(null);

  useEffect(() => {
    // Play the audio when the component is mounted
    const playAudio = async () => {
      try {
        if (audioRef.current) {
          await audioRef.current.play();
        }
      } catch (error) {
        console.error('Error playing audio:', error);
      }
    };

    playAudio();

    // Cleanup function to stop the audio when the component unmounts
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Reset the audio to start
      }
    };
  }, []);
  return (
    <>
  {/* <audio
        ref={audioRef}
        src="../assets/music/Sakura-Girl-Motivation-chosic.com_.mp3"
        preload="auto"
        loop // Set the audio to loop
      /> */}
      <Banner />
      <Games />
      <Footer />
    </>
    );
}

export default Home;
