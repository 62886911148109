import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import BottomMenuBar from '../components/BottomMenuBar';
import Games from '../components/Games';
import axios from 'axios';

const Index = () => {
  const [logo, setLogo] = useState('');
  const navigate = useNavigate();

  // Function to fetch content from the API
  const fetchContent = async (pageName) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api2/pagecontent', {
        pageName,
      });

      if (response.data && response.data.Content) {
        const fetchedContent = response.data.Content;
        setLogo(fetchedContent); // Set the logo URL
      } else {
        console.log('No content found in the response');
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log('Content not found');
      } else {
        console.log('Failed to call API:', error.message);
      }
    }
  };

  // Handle login button click
  const handleLoginClick = () => {
    navigate('/login');
  };

  // useEffect to fetch the logo when the component mounts
  useEffect(() => {
    fetchContent('Logo');
  }, []); // Empty array ensures this runs only once after the initial render

  // Construct the logo URL dynamically
  const logoUrl = `${logo}/logo.png`;

  return (
    <>
      <header className="header theme-colour">
        <img src={logoUrl} alt="Logo" className="logo2" />
        <button className="auth-button" onClick={handleLoginClick}>
          Login
        </button>
      </header>
      <Sidebar />
      <div className="container">
        <Games />
      </div>
      <BottomMenuBar />
    </>
  );
};

export default Index;
