// src/components/Support.js
import React, { useState, useEffect} from 'react';
import { Box, Typography, Grid,Card, CardContent } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import axios from 'axios';
import '../styles/Support.css';

const Support = () => {
  const [content, setContent] = useState('');
  const [content2, setContent2] = useState('');
  const [content3, setContent3] = useState('');
  const [content4, setContent4] = useState('');
  const whatsappLink = `https://wa.me/+91${content}`;
  const EmailLink = `mailto:${content2}`;
  const fetchContent = async (pageName) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api2/pagecontent', {
        pageName  
      });
      const Content = response.data.Content;
      setContent(Content);
    } catch (error) {
      if (error.response && error.response.status === 404) {
      } else {
        console.log('Failed to call API:', error.message);
      }
    }
  };
  const fetchContent2 = async (pageName) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api2/pagecontent', {
        pageName  
      });
      const Content = response.data.Content;
      setContent2(Content);
    } catch (error) {
      if (error.response && error.response.status === 404) {
      } else {
        console.log('Failed to call API:', error.message);
      }
    }
  };
  const fetchContent3 = async (pageName) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api2/pagecontent', {
        pageName  
      });
      const Content = response.data.Content;
      setContent3(Content);
    } catch (error) {
      if (error.response && error.response.status === 404) {
      } else {
        console.log('Failed to call API:', error.message);
      }
    }
  };
  const fetchContent4 = async (pageName) => {
    try {
      const response = await axios.post('https://api.successfamily.live/api2/pagecontent', {
        pageName  
      });
      const Content = response.data.Content;
      setContent4(Content);
    } catch (error) {
      if (error.response && error.response.status === 404) {
      } else {
        console.log('Failed to call API:', error.message);
      }
    }
  };
  useEffect(() => {
    // Play the audio when the component is mounted
    fetchContent('WhatsAppNo');
    fetchContent2('EmailID');
    fetchContent3('SupportNumber');
    fetchContent4('Address');
  }, []);
  return (
    <Box className="support-container">
      <Typography variant="h5" className="support-heading">Contact Us At Below Platforms.</Typography>
      <Grid container spacing={2} justifyContent="center" style={{marginTop: '5px'}}>
      <Grid item xs={6} sm={6} md={3} className="contact-item">
        <Card>
          <a href={EmailLink} style={{ textDecoration: 'none', color: 'inherit' }}>
            <CardContent>
              <EmailIcon style={{ fontSize: 50 }} />
              <Typography style={{ fontSize: '0.75rem', overflowWrap: 'anywhere' }} variant="body1">
              {content2}
              </Typography>
            </CardContent>
          </a>
        </Card>
      </Grid>
        <Grid item xs={6} sm={6} md={3} className="contact-item">
      <Card>
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
          <CardContent>
            <WhatsAppIcon style={{ fontSize: 50 }} />
            <Typography variant="body1">{content}</Typography>
          </CardContent>
        </a>
      </Card>
    </Grid>
        <Grid item xs={6} sm={6} md={3} className="contact-item">
        <Card>
      <CardContent>
          <PhoneIcon style={{ fontSize: 50 }} />
          <Typography style={{ fontSize: '0.75rem' }} variant="body1">{content3}</Typography>
        </CardContent>
        </Card>
        </Grid>
      </Grid>
      <Typography variant="body1" className="address">
        Address: {content4}
      </Typography>
    </Box>
  );
};

export default Support;
