import React, { useState, useEffect } from 'react';
import '../styles/Aviator.css'; // Ensure your CSS file is correctly linked
import planeImage from '../assets/p.png'; // Import the plane image
import backgroundImage from '../assets/bg-rotate-old.svg'; // Import the rotating background image

function Aviator2() {
  const [multiplier, setMultiplier] = useState(1);
  const [betAmount1, setBetAmount1] = useState(10);
  const [betAmount2, setBetAmount2] = useState(10);
  const [allBets, setAllBets] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isBetting, setIsBetting] = useState(false); // Track if a bet is placed
  const [betCashout, setBetCashout] = useState(0); // Track the cashout amount
  const [loading, setLoading] = useState(false); // Track if showing loading status
  const [roundComplete, setRoundComplete] = useState(false); // Track round completion
  const [autoBet, setAutoBet] = useState(true); // Track if auto-betting is active

  const handleBet = (amount) => {
    setIsBetting(true); // Set betting state to true
    setBetCashout(0); // Reset cashout amount
    setLoading(true); // Start showing loading status
    setRoundComplete(false); // Mark the round as not complete

    const newMultiplier = Math.random() * 20 + 1; // Generate random multiplier
    const newCashout = amount * newMultiplier;

    setMultiplier(1); // Reset multiplier at the start of a round

    setTimeout(() => {
      setMultiplier(newMultiplier);
      setBetCashout(newCashout); // Set cashout amount after the plane flew
      setIsBetting(false); // Reset betting state
      setLoading(false); // Stop showing loading status
      setRoundComplete(true); // Mark the round as complete
    }, 2000); // Show loader for 2 seconds before plane starts flying

    const newBet = {
      user: 'User', // Replace with actual user information
      betAmount: amount,
      multiplier: newMultiplier,
      cashout: newCashout,
    };
    setAllBets([...allBets, newBet]);
  };

  const handleCashout = () => {
    // Handle the cashout logic
    setBetCashout(0); // Reset cashout after cashing out
    setMultiplier(1); // Reset multiplier if user cashes out
  };

  useEffect(() => {
    if (roundComplete && autoBet) {
      const autoBetTimeout = setTimeout(() => {
      }, 2000); // Adjust delay as needed

      return () => clearTimeout(autoBetTimeout);
    }
  }, [roundComplete, autoBet, betAmount1]);

  useEffect(() => {
    if (!loading && !isBetting) {
      const interval = setInterval(() => {
        if (multiplier < 20) {
          setMultiplier(prevMultiplier => prevMultiplier + 0.05); // Increase multiplier gradually
        } else {
          clearInterval(interval);
        }
      }, 100); // Adjust interval for speed

      return () => clearInterval(interval);
    }
  }, [loading, isBetting, multiplier]);

  const handleBetAmountChange1 = (e) => {
    let value = parseInt(e.target.value, 10);
    if (isNaN(value)) {
      value = 10;
    } else if (value < 10) {
      value = 10;
    } else if (value > 8000) {
      value = 8000;
    }
    setBetAmount1(value);
  };

  const handleBetAmountChange2 = (e) => {
    let value = parseInt(e.target.value, 10);
    if (isNaN(value)) {
      value = 10;
    } else if (value < 10) {
      value = 10;
    } else if (value > 8000) {
      value = 8000;
    }
    setBetAmount2(value);
  };

  return (
    <div className="aviator-container">
      <div className="aheader">
        <div className="title">Aviator</div>
        <div className="balance">0.45 INR</div>
      </div>

      <div className="game-area">
        {/* Rotating SVG background */}
        <div className="rotating-background" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
        <div className="multiplier-display">{multiplier.toFixed(2)}x</div>
        {loading && (
          <div className="loading-screen">
            <p>Waiting for next round...</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120">
              <g fill="#E50539" fillRule="nonzero">
                {/* SVG path omitted for brevity */}
              </g>
            </svg>
          </div>
        )}
        {multiplier > 1 && (
          <img 
            src={planeImage} 
            alt="plane" 
            className="plane" 
            style={{ transform: `translateY(${-multiplier * 10}px)` }} 
          />
        )}
      </div>

      <div className="bet-area">
        <div className="bet-section">
          <input
            type="number"
            value={betAmount1}
            onChange={handleBetAmountChange1}
            min="10"
            max="8000"
            className="bet-input-field"
          />
          <button 
            onClick={() => handleBet(betAmount1)} 
            className={`bet-button ${isBetting ? 'betting' : ''}`}
          >
            {isBetting ? 'Cancel' : `BET ${betAmount1} INR`}
          </button>
          {roundComplete && (
            <button 
              onClick={handleCashout} 
              className="cashout-button"
            >
              Cashout {betCashout.toFixed(2)} INR
            </button>
          )}
        </div>
        <div className="bet-section">
          <input
            type="number"
            value={betAmount2}
            onChange={handleBetAmountChange2}
            min="10"
            max="8000"
            className="bet-input-field"
          />
          <button 
            onClick={() => handleBet(betAmount2)} 
            className={`bet-button ${isBetting ? 'betting' : ''}`}
          >
            {isBetting ? 'Cancel' : `BET ${betAmount2} INR`}
          </button>
          {roundComplete && (
            <button 
              onClick={handleCashout} 
              className="cashout-button"
            >
              Cashout {betCashout.toFixed(2)} INR
            </button>
          )}
        </div>
      </div>

      <div className="bets-table">
        <h3>ALL BETS ({allBets.length})</h3>
        <table>
          <thead>
            <tr>
              <th>User</th>
              <th>Bet INR</th>
              <th>Multiplier</th>
              <th>Cashout INR</th>
            </tr>
          </thead>
          <tbody>
            {allBets.map((bet, index) => (
              <tr key={index}>
                <td>{bet.user}</td>
                <td>{bet.betAmount}</td>
                <td>{bet.multiplier.toFixed(2)}x</td>
                <td>{bet.cashout.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flew-away-header">
        <h3>Flew Away History</h3>
        <button onClick={() => setShowPopup(true)} className="history-button">Show History</button>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>Previous Bets</h3>
            <table>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Bet INR</th>
                  <th>Multiplier</th>
                  <th>Cashout INR</th>
                </tr>
              </thead>
              <tbody>
                {allBets.map((bet, index) => (
                  <tr key={index}>
                    <td>{bet.user}</td>
                    <td>{bet.betAmount}</td>
                    <td>{bet.multiplier.toFixed(2)}x</td>
                    <td>{bet.cashout.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={() => setShowPopup(false)} className="close-popup-button">Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Aviator2;
