import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styles from '../styles/ColourGame.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import activeImage from '../assets/activeImage.png';
import inactiveImage from '../assets/inactiveImage.png';
import BetModal from '../components/BetModal';
import axios from 'axios';

const ColourGame = ({ setShowBottomMenuBar }) => {
  // State Hooks
  const [activeButton, setActiveButton] = useState('Win Go 30Sec');
  const [playButtonText, setPlayButtonText] = useState('Win Go 30Sec');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Section, setSection] = useState('Win Go 30Sec');
  const [time, setTime] = useState(); 
  const [betid, setBetid] = useState(); 
  const [Apiid, setApiid] = useState('1'); // Default to 1 for Win Go 30Sec
  const [showOverlay, setShowOverlay] = useState(false);
  const [modalColor, setModalColor] = useState('#ff6666');
  const [betData, setBetData] = useState(''); 
  const [betType, setBetType] = useState('');
  const [activeMultiplier, setActiveMultiplier] = useState('X1');
  const [status, setStatus] = useState({ timeRemaining: 0, currentGameId: '' });

  // Memoized Constants
  const multipliers = useMemo(() => ['X1', 'X5', 'X10', 'X20', 'X50', 'X100'], []);
  const colors = useMemo(() => [
    'linear-gradient(to bottom right, #fb5b5b 50%, #c86eff 0)',
    '#18b660', 
    '#fb5b5b', 
    '#18b660', 
    '#fb5b5b', 
    'linear-gradient(to bottom right, #18b660 50%, #c86eff 0)', 
    '#fb5b5b', 
    '#18b660', 
    '#fb5b5b', 
    '#18b660'
  ], []);
  const ballImages = useMemo(() => [
    'ball0.png',
    'ball1.png',
    'ball2.png',
    'ball3.png',
    'ball4.png',
    'ball5.png',
    'ball6.png',
    'ball7.png',
    'ball8.png',
    'ball9.png'
  ], []);
  const buttons = useMemo(() => [
    { label: 'Win Go 30Sec', data: '1', image: activeButton === 'Win Go 30Sec' ? activeImage : inactiveImage },
    { label: 'Win Go 1Min', data: '2', image: activeButton === 'Win Go 1Min' ? activeImage : inactiveImage },
    { label: 'Win Go 3Min', data: '3', image: activeButton === 'Win Go 3Min' ? activeImage : inactiveImage },
    { label: 'Win Go 5Min', data: '4', image: activeButton === 'Win Go 5Min' ? activeImage : inactiveImage },
  ], [activeButton]);

  // Effects
  useEffect(() => {
    setShowBottomMenuBar(false);
    return () => setShowBottomMenuBar(true);
  }, [setShowBottomMenuBar]);

  useEffect(() => {
    let timer;
    
    if (time > 0) {
      timer = setTimeout(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }
  
    if (time <= 5 && time > 0) {
      setShowOverlay(true);
    }
  
    return () => clearTimeout(timer);
  }, [time]);

  useEffect(() => {
    if (time === 0) {
      fetchGameStatus(Apiid); // Fetch status again after time is over
      setShowOverlay(false);
    }
  }, [time, Apiid]);

  const fetchGameStatus = useCallback(async (no) => {
    try {
      const response = await axios.post(`https://api.successfamily.live/api2/gamestatus${no}`);
      setTime(response.data.timeRemaining);
      setBetid(response.data.currentGameId);
      // Avoid unnecessary recursive calls
      if(response.data.timeRemaining === 0){
        setTime(0); // Reset or set some state to indicate time is over
      }
    } catch (error) {
      console.error('Failed to fetch game status:', error);
      toast.error('Error fetching game status');
    }
  }, []);

  useEffect(() => {
    fetchGameStatus(Apiid); // Fetch initial status for the default button
  }, [Apiid, fetchGameStatus]);

  const formatTime = useCallback((time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return {
      minutes: minutes.toString().padStart(2, '0'),
      seconds: seconds.toString().padStart(2, '0')
    };
  }, []);

  const { minutes, seconds } = formatTime(time);

  const handleClick = useCallback((label, data) => {
    setApiid(data);
    setActiveButton(label);
    setPlayButtonText(label);
    fetchGameStatus(data);
  }, [fetchGameStatus]);

  const handleBetClick = useCallback((data, color, betTy, gameTime) => {
    setBetData(data);
    setModalColor(color);
    setIsModalOpen(true);
    setBetType(betTy);
    setSection(gameTime);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleConfirmBet = useCallback(() => {
    // Add your logic to handle the bet confirmation
    setIsModalOpen(false);
  }, []);

  const renderCharacter = useCallback((char) => {
    if (/[0-9]/.test(char)) {
      return (
        <img
          src={require(`../assets/ball/${char}.png`)}
          alt={char}
          className={styles.imageStyle}
        />
      );
    }
    return <span>{char}</span>;
  }, []);

  return (
    <div className={styles.container}>
      <ToastContainer />
      <div className={styles.mainContent}>
        {isModalOpen && <div className={styles.overlayBackground} />}
        <div className={styles.instruction}>
          To visit our official website, be sure to use the link below,
          <a href="https://www.fairplaygames.info">https://www.fairplaygames.info</a>
        </div>
        <div className={styles.gameOptions}>
          {buttons.map((button) => (
            <button
              key={button.label}
              className={button.label === activeButton ? styles.activeOption : styles.inactiveOption}
              onClick={() => handleClick(button.label, button.data)}
            >
              <img src={button.image} alt="Timer Image" />
              <span>{button.label}</span>
            </button>
          ))}
        </div>

        <div className={styles.bettingsection}>
          {showOverlay && (
            <div className={styles.overlay}>
              <div className={styles.overlayTimer}>
                <div className={styles.boxStyle2}>{seconds[0]}</div>
                <div className={styles.boxStyle2}>{seconds[1]}</div>
              </div>
            </div>
          )}
          <div className={styles.timer}>
            <div className={styles.howToPlay}>
              <button>How to play</button>
              <div className={styles.playbutton}>{playButtonText}</div>
              <div style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                {renderCharacter('ball6')}
                {renderCharacter('ball8')}
                {renderCharacter('ball1')}
                {renderCharacter('ball5')}
                {renderCharacter('ball9')}
              </div>
            </div>
            <div className={styles.Remaningtimer}>
              <div>Time remaining</div>
              <div style={{ display: 'flex', backgroundColor: '#ff6666', padding: '10px' }}>
                <div className={styles.boxStyle}>{minutes[0]}</div>
                <div className={styles.boxStyle}>{minutes[1]}</div>
                <div style={{ padding: '0 5px', color: '#fff', fontWeight: 'bold' }}>:</div>
                <div className={styles.boxStyle}>{seconds[0]}</div>
                <div className={styles.boxStyle}>{seconds[1]}</div>
              </div>
              <div className={styles.gameId}>{betid}</div>
            </div>
          </div>
          <div className={styles.betChoice}>
            <button className={styles.greenbutton} onClick={() => handleBetClick('Green', '#18b660', 'Color',playButtonText)}>Green</button>
            <button className={styles.violetbutton} onClick={() => handleBetClick('Violet', '#c86eff', 'Color',playButtonText)}>Violet</button>
            <button className={styles.redbutton} onClick={() => handleBetClick('Red', '#fb5b5b', 'Color',playButtonText)}>Red</button>
          </div>
          <div className={styles.numberOptions}>
            {ballImages.map((image, index) => (
              <div 
                key={index} 
                className={styles.ballImage} 
                onClick={() => handleBetClick(`${index}`, colors[index], 'Number',playButtonText)}
              >
                <img src={require(`../assets/ball/${image}`)} alt={`Ball ${index}`} />
              </div>
            ))}
          </div>
          <div className={styles.modalLineList}>
            {multipliers.map((multiplier) => (
              <div
                key={multiplier}
                className={`${styles.modalLineItem} ${activeMultiplier === multiplier ? styles.activeItem : ''}`}
                onClick={() => setActiveMultiplier(multiplier)}
              >
                {multiplier}
              </div>
            ))}
          </div>
          <div className={styles.betChoice2}>
            <button className={styles.bigbutton} onClick={() => handleBetClick('Big', '#feaa57','Size',playButtonText)}>Big</button>
            <button className={styles.smallbutton} onClick={() => handleBetClick('Small', '#6ea8f4','Size',playButtonText)}>Small</button>
          </div>
        </div>
        <div className={styles.footer}>
          <button>Game History</button>
          <button>Chart</button>
          <button>My History</button>
        </div>
      </div>
      <BetModal 
        isOpen={isModalOpen} 
        onClose={handleCloseModal} 
        onConfirm={handleConfirmBet} 
        color={modalColor} 
        betData={betData} 
        multipliersamount={activeMultiplier}
        betType={betType}
        gametime={Section}
        betid={betid}
      />
    </div>
  );
};

export default ColourGame;
